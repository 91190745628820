<template>
    <div :class="{
        'max-w-screen-lg mx-auto fixed bg-white inset-x-5 p-5 bottom-4 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between': true,
        'hidden': !this.displayBanner
    }">
        <div class="w-full">
            Deze website gebruikt cookies om de beste ervaring te bieden.
            <a href="/cookies" class="text-primary-700 whitespace-nowrap hover:underline">
                Lees meer
            </a>
        </div>
        <div class="flex gap-4 items-center flex-shrink-0">
            <a href="https://google.com" class="text-primary-700 focus:outline-none hover:underline">
                Weigeren
            </a>
            <button @click="this.acceptCookies()" class="bg-primary-500 px-5 py-2 text-white rounded-md hover:bg-primary-600 focus:outline-none">
                Dat snap ik!
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            displayBanner: true
        };
    },
    methods: {
        getCookie(name) {
            var dc = document.cookie;
            var prefix = name + "=";
            var begin = dc.indexOf("; " + prefix);
            if (begin == -1) {
                begin = dc.indexOf(prefix);
                if (begin != 0) return null;
            } else {
                begin += 2;
                var end = document.cookie.indexOf(";", begin);
                if (end == -1) {
                    end = dc.length;
                }
            }
            return decodeURI(dc.substring(begin + prefix.length, end));
        },
        acceptCookies() {
            this.displayBanner = false;
            axios.get('/acceptcookies').then((response) => {});
        }
    },
    beforeMount() {
        if(this.getCookie('accept_cookies') != null) {
            this.displayBanner = false;
        }
    }
}
</script>